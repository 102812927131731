import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import footerStyles from '../styles/css/footer.module.scss'
import mainStyles from '../styles/css/main.module.scss'

class Subscribe extends React.Component {
    constructor() {
        super()
        this.state = {
            email: '',
            msg: '',
            status: '',
        }
    }
    // Update state each time user edits their email address
    _handleEmailChange = e => {
        this.setState({ email: e.target.value })
    }
    // Post to MC server & handle its response
    _postEmailToMailchimp = (email) => {
        addToMailchimp(email)
            .then(result => {
                // Mailchimp always returns a 200 response
                // So we check the result for MC errors & failures
                if (result.result !== `success`) {
                    this.setState({
                        status: `error`,
                        msg: result.msg,
                    })
                } else {
                    // Email address succesfully subcribed to Mailchimp
                    this.setState({
                        status: `success`,
                        msg: result.msg,
                    })
                }
                console.log(result)
            })
            .catch(err => {
                // Network failures, timeouts, etc
                this.setState({
                    status: `error`,
                    msg: err,
                })
            })
    }
    _handleFormSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        if (!this.state.email) {
            this.setState({
                status: `error`,
                msg: "Please enter a valid email",
            })
        }
        else {
            this.setState(
                {
                    status: `sending`,
                    msg: '',
                }
            )
            // setState callback (subscribe email to MC)
            this._postEmailToMailchimp(this.state.email, {
                pathname: document.location.pathname,
            })
        }
    }
    render() {
        return (
            <div>
                {this.state.status === `success` ? (
                    <p>You're subscribed!</p>
                ) : (
                        <div>
                            <form
                                id="email-capture"
                                method="post"
                                noValidate
                            >
                                <div className={footerStyles.subContainer}>
                                    <input className={footerStyles.subInputForm}
                                        placeholder="Your Email"
                                        onChange={this._handleEmailChange}
                                        required
                                    />
                                    <button
                                        className={`${mainStyles.btn_small} ${mainStyles.btn_primary}`}
                                        type="submit"
                                        onClick={this._handleFormSubmit}
                                    >Subscribe
                                    </button>
                                    {this.state.status === `error` && (
                                        <p>{this.state.msg}</p>
                                    )}
                                </div>
                            </form>
                        </div>
                    )}
            </div>
        )
    }
}

export default Subscribe