import { Link } from "gatsby"
import React from "react"

import footerStyles from "../styles/css/footer.module.scss"
import mainStyles from "../styles/css/main.module.scss"
import Subscribe from './subscribe'

const Footer = () => (
    <footer className={footerStyles.footer}>
        <article className={footerStyles.outlinks}>
            <div className={mainStyles.mainPadding}>
                <div className={[mainStyles.mainExplanatoryContent_wide, mainStyles.white].join(' ')}>
                    <h2>Want more good stuff?</h2>
                    <p>Get updates straight to your inbox</p>
                    <Subscribe />
                </div>
            </div>
        </article>

        <div className={footerStyles.copyright}>
        <p>Emojis by <a href="https://github.com/googlefonts/noto-emoji/blob/main/LICENSE" target="_blank">Noto Emoji</a></p>
        </div>
        
    </footer>
)

export default Footer
