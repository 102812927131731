import { Link } from "gatsby"
import React from "react"

import headerStyles from "../styles/css/header.module.scss"
const logo = require("../img/logo/logo_black.png")
const menu = require("../img/icons/ic_menu.svg")

class Header extends React.Component {

  // create initial state for header styling
  constructor(props){
    super(props);
    this.state = {
      headStyling: headerStyles.header,
      menuClicked: headerStyles.nav_universal,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  // adds a window listener for scroll on mount. calls the handleScroll fcn
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  // removes window listener on dismount
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // fcn to check scroll position to determine css change
  handleScroll = () => {
    if(window.scrollY > 50){
      this.setState({headStyling: headerStyles.header_bg});
    }
      else{
        this.setState({headStyling: headerStyles.header});
      }
  }

  // fcn to show/hide mobile menu
  handleClick() {
    if(this.state.menuClicked === headerStyles.nav_universal) {
      this.setState({menuClicked: headerStyles.nav_universal_show});
    }
    else {
      this.setState({menuClicked: headerStyles.nav_universal});
    }
  }

  // set wrapper
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  // fcn for click outside menu
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({menuClicked: headerStyles.nav_universal});
    }
  }

  render(){
    let btn_class = this.state.menuClicked;

    return(
      <header className={this.state.headStyling} ref={this.setWrapperRef}>
        <div className={headerStyles.header_container}>     
          <Link to= "/" className={[headerStyles.logo, headerStyles.nav_link].join(' ')}>
            <div className={headerStyles.logo_trademark}>
              <img src={logo} alt="wing logo"/>
            </div>
            <div className={headerStyles.logo_textmark}>UX</div>
          </Link>
        
          <img className={headerStyles.nav_menu} src={menu} alt="menu" onClick={this.handleClick.bind(this)}/>

          <div className={btn_class}>
            <div className={headerStyles.nav_universal_container}> 

              <Link to="/guide" className={headerStyles.nav_item}>
                <p className={headerStyles.nav_link}>Job Guide</p>
              </Link>

              <Link to="/cases" className={headerStyles.nav_item}>
                <p className={headerStyles.nav_link}>Beginner Cases</p>
              </Link>

              <a className={headerStyles.nav_item} href="https://www.lumenful.com/read/ux-cases" target="_blank" rel="noopener noreferrer">
                <p className={headerStyles.nav_link}>Advanced Cases</p>
              </a>
              
            </div>
          </div>
        
        </div>
      </header> 
    )
  }
}

export default Header